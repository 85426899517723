import moment from 'moment';
import linkifyElement from 'linkify-element';

const { v4: uuidv4 } = require('uuid');

var link = document.createElement( "link" );
link.href = `${process.env.MIX_PUSHER_HOST}/dist/css/sigma-chat-v2.css`;
link.type = "text/css";
link.rel = "stylesheet";

document.getElementsByTagName( "head" )[0].appendChild( link );

window.addEventListener('load', function(){
  var elem = document.querySelector(`script[src="${process.env.MIX_PUSHER_HOST}/dist/js/sigma-chat-v2.js"]`);
  if(!elem){
    elem = document.querySelector(`script[src="/dist/js/sigma-chat-v2.js"]`);
  }

  document.documentElement.style.setProperty('--sigma-primary-color', elem.getAttribute('data-primary-color') ?? (process.env.MIX_DIALOGI === "true" ? '#2C40F5' : '#e53012'));
  document.documentElement.style.setProperty('--sigma-secondary-color', elem.getAttribute('data-secondary-color') ?? (process.env.MIX_DIALOGI === "true" ? '#27D76D' : '#323e48'));
  document.documentElement.style.setProperty('--sigma-background-chat', elem.getAttribute('data-background-color') ?? '#fff');
  document.documentElement.style.setProperty('--sigma-font-color-popup', elem.getAttribute('data-popup-font-color') ?? '#fff');
  document.documentElement.style.setProperty('--sigma-start-chat-color', elem.getAttribute('data-btn-color') ?? (process.env.MIX_DIALOGI === "true" ? '#27D76D' : '#c42a0f'));
  document.documentElement.style.setProperty('--sigma-start-chat-hover-color', elem.getAttribute('data-btn-hover-color') ?? (process.env.MIX_DIALOGI === "true" ? '#27D76D' : '#c42a0f'));
  document.documentElement.style.setProperty('--sigma-font-size', elem.getAttribute('data-font-size') ?? '16px');
  document.documentElement.style.setProperty('--sigma-font-family', elem.getAttribute('data-font-family') ?? '"Times New Roman",Times,serif');

  var logo = process.env.MIX_DIALOGI === "true" ? {src: `${process.env.MIX_PUSHER_HOST}/vendor/adminlte/dist/img/dialogi.png`, height: '40px'} : {src: `${process.env.MIX_PUSHER_HOST}/vendor/adminlte/dist/img/Sigma_positive_2.png`, height: '60px'};
  if(elem.getAttribute('data-logo')) {
    try{
      logo = JSON.parse(elem.getAttribute('data-logo'));
    } catch(e){
      logo.src = elem.getAttribute('data-logo');
    }
  }
  var icon = process.env.MIX_DIALOGI === "true" ? {src: `${process.env.MIX_PUSHER_HOST}/vendor/adminlte/dist/img/dialogi_icon.png`, height: '40px'} : {src: `${process.env.MIX_PUSHER_HOST}/vendor/adminlte/dist/img/Favicon_Nuveto_SIGMA.png`, height: '60px'};
  if(elem.getAttribute('data-icon')) {
    try{
      icon = JSON.parse(elem.getAttribute('data-icon'));
    } catch(e){
      icon.src = elem.getAttribute('data-icon');
    }
  }

  var lang = "en";
  if(elem.getAttribute('data-lang')) {
    try{
      lang = JSON.parse(elem.getAttribute('data-lang'));
    } catch(e){
      lang = elem.getAttribute('data-lang');
    }
  }

  var user = null;
  if(elem.getAttribute('data-user')) {
    try{
      user = JSON.parse(elem.getAttribute('data-user'));
    } catch(e){
      console.log('Sigma Chat: Invalid user data');
      user = null;
    }
  }

  var confirmMessage = null;
  if(elem.getAttribute('data-confirm-message')) {
    try{
      confirmMessage = JSON.parse(elem.getAttribute('data-confirm-message'));
    } catch(e){
      confirmMessage = null;
    }
  }

  var links = [];
  if(elem.getAttribute('data-FAB-links')) {
    try{
      links = JSON.parse(elem.getAttribute('data-FAB-links'));
    } catch(e){
      links = [];
    }
  }

  var phone = {};
  if(elem.getAttribute('data-form-phone')) {
    try{
      phone = JSON.parse(elem.getAttribute('data-form-phone'));
    } catch(e){
      phone = {};
    }
  }

  var fields = [];
  if(elem.getAttribute('data-form-fields')) {
    try{
      fields = JSON.parse(elem.getAttribute('data-form-fields'));
    } catch(e){
      fields = [];
    }
  }

  var hideField = [];
  if(elem.getAttribute('data-form-hide-fields')) {
    try{
      hideField = JSON.parse(elem.getAttribute('data-form-hide-fields'));
    } catch(e){
      hideField = [];
    }
  }

  new SigmaChat({
    id: elem.getAttribute('data-id') ?? 'sigmachatid',
    title: elem.getAttribute('data-title') ?? (process.env.MIX_DIALOGI === "true" ? 'Chat Dialogi' : 'Chat Sigma'),
    headline: elem.getAttribute('data-headline') ?? 'Welcome',
    logo,
    icon,
    dateFormat: elem.getAttribute('data-date-format') ?? 'HH:mm',
    drawFAB: elem.getAttribute('data-no-FAB') == null,
    autoMessage: elem.getAttribute('data-auto-message') ?? null,
    confirmMessage,
    keepHistory: elem.getAttribute('data-history'),
    canReact: elem.getAttribute('data-react') != null,
    canReply: elem.getAttribute('data-reply') != null,
    canSendFiles: elem.getAttribute('data-no-files') == null,
    canSendAudio: elem.getAttribute('data-send-audio') != null,
    hasSurvey: elem.getAttribute('data-survey') != null,
    canSendFilesSurvey: elem.getAttribute('data-no-files-survey') == null,
    newChat: elem.getAttribute('data-hide-new-chat') == null,
    menuList: elem.getAttribute('data-menu-list') != null,
    canSendFilesSurvey: elem.getAttribute('data-no-files-survey') == null,
    newChat: elem.getAttribute('data-hide-new-chat') == null,
    noConfirmTerminate: elem.getAttribute('data-no-confirm-terminate') != null,
    color: elem.getAttribute('data-FAB-color') ?? '#323E48',
    links,
    fields,
    hideField,
    phone,
    assistent: elem.getAttribute('data-assistent') ?? null,
    timerPopup: elem.getAttribute('data-timer-popup') ?? 5000,
    titlePopUp: elem.getAttribute('data-title-popup') ?? '',
    messagePopUp: elem.getAttribute('data-message-popup') ?? '',
    lang,
    footer: elem.getAttribute('data-form-footer') ?? '',
    fileSize: elem.getAttribute('data-file-size') ?? 20,
    user
  });
});

export class SigmaChat {
  static #lang_es = {
    "name": "Nombre",
    "namePH": "Escriba su nombre",
    "email": "Email",
    "emailPH": "Escriba su email",
    "phone": "Teléfono",
    "phonePH": "Escriba su teléfono",
    "submit": "Enviar",
    "assistance": "Asistencia",
    "message": "Mensaje",
    "messagePH": "Escribe tu mensaje aquí...",
    "download": "Descargar",
    "terminated": "Chat terminado",
    "connecting": "Conectando...",
    "offline": "Disculpe, pero estamos experimentando dificultades técnicas, lo que hace temporalmente imposible conectarse al chat web.",
    "retry": "Intentar de nuevo",
    "quit": "¿Está seguro que desea finalizar la conversación del chat?",
    "yes": "Si",
    "no": "No",
    "newChat": "Nuevo chat",
    "fileSize": "Fallo al enviar, el archivo seleccionado no puede superar los 20 MB",
    "fileSizeSystem": "Mensaje del sistema: El usuario intentó enviar un archivo superior a 20 MB",
    "menuOptions": "Menu de Opciones",
  };
  static #lang_en = {
    "name": "Name",
    "namePH": "Type your name",
    "email": "Email",
    "emailPH": "Type your email",
    "phone": "Phone",
    "phonePH": "Type your phone",
    "submit": "Submit",
    "assistance": "Assistance",
    "message": "Message",
    "messagePH": "Type your message here...",
    "download": "Download",
    "terminated": "Chat terminated",
    "connecting": "Connecting...",
    "offline": "Apologies, but we're currently experiencing technical difficulties, making it temporarily impossible to connect to the chat web.",
    "retry": "Try again",
    "quit": "Are you sure you want to end the chat conversation?",
    "yes": "Yes",
    "no": "No",
    "newChat": "New chat",
    "fileSize": "Send failure, the selected file cannot exceed 20 MB",
    "fileSizeSystem": "System Message: The user attempted to send a file larger than 20 MB.",
    "menuOptions": "Menu Options",
  };
  static #lang_pt = {
    "name": "Nome",
    "namePH": "Digite seu nome",
    "email": "Email",
    "emailPH": "Digite seu email",
    "phone": "Telefone",
    "phonePH": "Digite seu telefone",
    "submit": "Enviar",
    "assistance": "Atendimento",
    "message": "Mensagem",
    "messagePH": "Digite sua menssagem aqui...",
    "download": "Baixar",
    "terminated": "Chat finalizad",
    "connecting": "Conectando...",
    "offline": "Desculpe, mas estamos enfrentando dificuldades técnicas, tornando temporariamente impossível conectar-se ao chat web.",
    "retry": "Tentar novamente",
    "quit": "Tem certeza que deseja encerrar a conversa do chat?",
    "yes": "Sim",
    "no": "Não",
    "newChat": "Novo chat",
    "fileSize": "Falha no envio, o arquivo selecionado não pode ultrapassar 20 MB",
    "fileSizeSystem": "Mensagem do Sistema: O usuário tentou enviar um arquivo superior a 20 MB.",
    "menuOptions": "Opções de Menu",
  };
  static #user = null;
  static #fromForm = false;
  static #clientId = null;
  static #atSurvey = false;
  static #atChat = false;
  static #unseenMessages = 0;
  static #minimized = false;
  static #audios = {};
  static #currentAudio = null;
  static #state = 'disconnected';
  static #healthCheck = null;
  static #options = {
    id: 'sigmachatid',
    title: 'Chat Sigma',
    headline: 'Digite seu Número e Nome',
    logo: null,
    icon: null,
    userIcon: null,
    dateFormat: 'HH:mm',
    drawFAB: true,
    autoMessage: null,
    confirmMessage: null,
    keepHistory: null,
    canReact: false,
    canReply: false,
    canSendFiles: true,
    canSendAudio: false,
    hasSurvey: false,
    noConfirmTerminate: false,
    color: '#323E48',
    fields: [],
    hideField: [],
    phone: {},
    links: [],
    assistent: null,
    timerPopup: 5000,
    titlePopUp: '',
    messagePopUp: '',
    lang: "en",
    footer:'',
    user: null
  }
  constructor(options) {
    var showFab = false;
    SigmaChat.#options = { ...SigmaChat.#options, ...options };
    SigmaChat.#options.phone = {
      code: null,
      pattern: "\\+\\d{7,15}",
      title: "Number format E164. E.g. +9999999999",
      maskOptions: {
        mask: '{+}0000000[00000000]'
      },
      ...SigmaChat.#options.phone
    }
    if (typeof(SigmaChat.#options.lang) == 'string') {
      switch (SigmaChat.#options.lang) {
        case 'es':
          SigmaChat.#options.lang = SigmaChat.#lang_es;
          break;
        case 'pt':
          SigmaChat.#options.lang = SigmaChat.#lang_pt;
          break;
        default:
          SigmaChat.#options.lang = SigmaChat.#lang_en;
          break;
      }
    } else {
      switch (SigmaChat.#options.lang?.base) {
        case 'es':
          SigmaChat.#options.lang = {...SigmaChat.#lang_es, ...SigmaChat.#options.lang};
          break;
        case 'pt':
          SigmaChat.#options.lang = {...SigmaChat.#lang_pt, ...SigmaChat.#options.lang};
          break;
        default:
          SigmaChat.#options.lang = {...SigmaChat.#lang_en, ...SigmaChat.#options.lang};
          break;
      }
    }

    if (SigmaChat.#options.drawFAB) {
      this.#drawFAB();
      showFab = true;
    } else {
      const fab = document.querySelector('div.sigma-fab');
      if(fab){
        fab.remove();
      }
    }
    if (options?.user) {
      SigmaChat.#user = options.user;
    }
    this.#drawMinimizedChat();
    if(options.keepHistory === ''){
      options.keepHistory = 30;
      SigmaChat.#options.keepHistory = 30;
    }
    if(sessionStorage && options.keepHistory){
      const history = JSON.parse(sessionStorage.getItem('sigma-chat-history'));

      if(history && moment().subtract(options.keepHistory, 'minutes').isBefore(moment(history.lastModified))){
        showFab = !history.minimized;
        SigmaChat.#user = history.user;
        SigmaChat.#fromForm = history.fromForm;
        SigmaChat.#clientId = history.clientId;
        SigmaChat.#startChat(true, history.minimized);
        history.messages.forEach(message => {
          SigmaChat.#renderMessage(message, true);
        });
        if(history.minimized){
          var icon = document.querySelector('.sigma-minimized');
          if(icon){
            icon.style.display = 'flex';
          }
        }
      } else {
        sessionStorage.removeItem('sigma-chat-history');
      }
    }
    if(showFab){
        const fab = document.querySelector('.sigma-fab');
        if(fab){
          fab.style.display = 'block';
        }
    }

  }

  #drawMinimizedChat() {
    let root = document.querySelector('body div.sigma-minimized')
    if (!root) {
      root = document.createElement('div');
      root.classList.add('sigma-minimized');
      root.setAttribute('onclick', 'SigmaChat.minMaxChat()');
      document.body.appendChild(root);
    }
    root.replaceChildren();

    const img = document.createElement('img');
    img.classList.add('sigma-minimized-img');

    img.setAttribute('src', SigmaChat.#options?.icon?.src || `${process.env.MIX_PUSHER_HOST}/dist/img/Favicon_Nuveto_SIGMA.png`);
    root.appendChild(img);

    const bg = document.createElement('div');
    bg.classList.add('sigma__bg-number-of-messages');
    bg.innerHTML = `<p class="sigma__number-of-messages"></p>`
    root.appendChild(bg);
  }

  #drawFAB() {
    if(SigmaChat.#options.assistent == true) {
      setTimeout(() => {
        const popup = document.createElement('div');
        popup.classList.add('sigma-popup');
        popup.innerHTML = `
        <div class="sigma-popup-header">
          <div class="sigma-popup-title">
            <div class='sigma-popup-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
            </div>
            <p class='sigma-popup-messege'>
              ${SigmaChat.#options.titlePopUp}
            </p>
          </div>
          <div>
            <button type='button' class='sigma-popup-close'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="sigma-popup-body">
          <p class='txt-help'>${SigmaChat.#options.messagePopUp}</p>
        </div>
        `;
        document.body.appendChild(popup);

        const closePopup = document.querySelector('.sigma-popup-close');
        closePopup.setAttribute('onclick', 'SigmaChat.closePopup()');

      }, SigmaChat.#options.timer);
    }
    let root = document.querySelector('body div.sigma-fab')
    if (!root) {
      root = document.createElement('div');
      root.classList.add('sigma-fab');
      root.setAttribute('ontouchstart', "");
      document.body.appendChild(root);
    }
    root.replaceChildren();

    const main = document.createElement('button');
    main.classList.add('sigma-main');
    main.style.backgroundColor = SigmaChat.#options.color;
    if (SigmaChat.#options.links.length > 0) {
      main.addEventListener('click', function () {
        const btn = document.querySelector('.sigma-fab button.sigma-main');
        btn.classList.toggle('sigma-visible');
      })
    } else {
      main.setAttribute('onclick', 'SigmaChat.showChat()');
    }
    root.appendChild(main);

    const btnImg = document.createElement('img');
    btnImg.setAttribute('id', `sigma-fab-img`);
    btnImg.setAttribute('src', `${process.env.MIX_PUSHER_HOST}/dist/img/chat.svg`);
    main.appendChild(btnImg);

    const ul = document.createElement('ul');
    root.appendChild(ul);

    SigmaChat.#options.links.forEach((link, index) => {
      const li = document.createElement('li');
      ul.appendChild(li);
      const label = document.createElement('label');
      label.classList.add('sigma-fab-label');
      label.setAttribute('for', `sigma-fab-link-${index}`);
      label.innerHTML = link.label || '';
      li.appendChild(label);

      const a = document.createElement('a');
      a.classList.add('sigma-fab-btn');
      a.setAttribute('id', `sigma-fab-link-${index}`);
      a.setAttribute('href', link.href || '#');
      a.setAttribute('target', '_blank');
      a.style.backgroundColor = link.color || '#748F9F';
      li.appendChild(a);

      const img = document.createElement('img');
      img.setAttribute('id', `sigma-fab-link-${index}-img`);
      img.setAttribute('src', link.img);
      a.appendChild(img);
    });

    if (SigmaChat.#options.links.length > 0) {
      const li = document.createElement('li');
      ul.appendChild(li);
      const label = document.createElement('label');
      label.setAttribute('for', 'sigma-fab-link-web');
      label.innerHTML = 'Chat';
      li.appendChild(label);

      const a = document.createElement('a');
      a.setAttribute('id', 'sigma-fab-link-web');
      // a.setAttribute('href', '#');
      a.setAttribute('onclick', 'SigmaChat.showChat()');
      a.style.backgroundColor = '#748F9F';
      li.appendChild(a);

      const img = document.createElement('img');
      img.setAttribute('id', `sigma-fab-link-web-img`);
      img.setAttribute('src', `${process.env.MIX_PUSHER_HOST}/dist/img/web.svg`);
      a.appendChild(img);
    }

  };

  static #drawChat() {
    let root = document.querySelector('body div.sigma-container')
    if (!root) {
      root = document.createElement('div');
      root.classList.add('sigma-container');
      document.body.appendChild(root);
    }
    root.replaceChildren();

    const btnClose = document.createElement('a');
    btnClose.classList.add('sigma-btn-close');
    btnClose.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="sigma-bi sigma-bi-x-lg" viewBox="0 0 16 16" style="color: black;margin-top: 1vw;">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"></path>
  </svg>`
    // btnClose.setAttribute('href', '#');
    btnClose.setAttribute('onclick', 'SigmaChat.closeChat()');
    root.appendChild(btnClose);

    const span = document.createElement('span');
    span.classList.add('sigma-btn-close');
    span.style.float = 'left';
    span.style.content = 'none';
    root.appendChild(span);

    const form = document.createElement('form');
    form.classList.add('sigma-form-inputs');
    form.setAttribute('id', 'sigma-chat');
    form.setAttribute('onsubmit', 'event.preventDefault();SigmaChat.initChat()');
    root.appendChild(form);

    if (SigmaChat.#options.logo && (SigmaChat.#options.logo instanceof Object)) {
      const img = document.createElement('img');
      img.classList.add('sigma-logo');
      const customClasses = (SigmaChat.#options.logo.class || "").split(" ").filter(value => value !== '');
      if (customClasses.length > 0) {
        img.classList.add(...customClasses);
      }
      Object.entries(SigmaChat.#options.logo).forEach(attr => {
        if (['class', 'id', 'style'].includes(attr[0])) {
          return;
        }
        img.setAttribute(attr[0], attr[1]);
      });
      img.setAttribute('id', `sigma-logo`);
      form.appendChild(img);
    } else {
      const span = document.createElement('span');
      span.classList.add('sigma-title');
      span.innerHTML = SigmaChat.#options.title;
      form.appendChild(span);
    }


    const p = document.createElement('p');
    p.classList.add('sigma-headline');
    p.innerHTML = SigmaChat.#options.headline;
    form.appendChild(p);

    if (SigmaChat.#options.hideField.includes('name') == false) {
      const divName = document.createElement('div');
      form.appendChild(divName);

      const labelName = document.createElement('label');
      labelName.classList.add('sigma-label');
      labelName.setAttribute('for', 'sigma-input-name');
      labelName.innerText = SigmaChat.#options.lang.name;
      divName.appendChild(labelName);

      const inputName = document.createElement('input');
      inputName.classList.add('sigma-input');
      inputName.setAttribute("type", "text");
      inputName.setAttribute("name", "firstName");
      inputName.setAttribute("required", "");
      inputName.setAttribute('placeholder', SigmaChat.#options.lang.namePH);
      inputName.setAttribute('id', 'sigma-input-name');
      divName.appendChild(inputName);
    }

    if (SigmaChat.#options.hideField.includes('email') == false) {
      const divEmail = document.createElement('div');
      form.appendChild(divEmail);

      const labelEmail = document.createElement('label');
      labelEmail.classList.add('sigma-label');
      labelEmail.setAttribute('for', 'sigma-input-email');
      labelEmail.innerText = SigmaChat.#options.lang.email;
      divEmail.appendChild(labelEmail);

      const inputEmail = document.createElement('input');
      inputEmail.classList.add('sigma-input');
      inputEmail.setAttribute("type", "email");
      inputEmail.setAttribute("name", "email");
      inputEmail.setAttribute("required", "");
      inputEmail.setAttribute("placeholder", SigmaChat.#options.lang.emailPH);
      inputEmail.setAttribute('id', 'sigma-input-email');
      divEmail.appendChild(inputEmail);
    }

    if (SigmaChat.#options.hideField.includes('phone') == false) {
      const divPhone = document.createElement('div');
      form.appendChild(divPhone);

      const labelPhone = document.createElement('label');
      labelPhone.classList.add('sigma-label');
      labelPhone.setAttribute('for', 'sigma-input-phone');
      labelPhone.innerHTML = SigmaChat.#options.lang.phone;
      divPhone.appendChild(labelPhone);

      const inputPhone = document.createElement('input');
      inputPhone.classList.add('sigma-input');
      inputPhone.setAttribute("type", "tel");
      inputPhone.setAttribute("name", "number1");
      inputPhone.setAttribute("required", "");
      inputPhone.setAttribute("placeholder", SigmaChat.#options.lang.phonePH);
      inputPhone.setAttribute('id', 'sigma-input-phone');
      divPhone.appendChild(inputPhone);

      IMask(inputPhone, SigmaChat.#options.phone.maskOptions)
      if (SigmaChat.#options.phone.pattern) {
        inputPhone.setAttribute("pattern", SigmaChat.#options.phone.pattern);
        inputPhone.setAttribute("title", SigmaChat.#options.phone.title);
      }
    }

    SigmaChat.#options.fields.forEach((field, index) => {

      const div = document.createElement('div');
      form.appendChild(div);

      const label = document.createElement('label');
      if (field.type === "checkbox") {
        label.classList.add('sigma-label-checkbox');
        label.classList.add('sigma-label');
      } else {
        label.classList.add('sigma-label');
      }
      label.setAttribute('for', `sigma-input-field-${index}`);
      label.innerText = field.label || '';
      div.appendChild(label);
      if(Array.isArray(field.options)){
        const select = document.createElement('select');
        select.classList.add('sigma-input-select');
        const customClasses = (field.class || "").split(" ").filter(value => value !== '');
        if (customClasses.length > 0) {
          select.classList.add(...customClasses);
        }
        select.setAttribute('id', `sigma-input-field-${index}`);
        Object.entries(field).forEach(attr => {
          if (['class', 'label', 'id', 'style', 'maskOptions', 'options'].includes(attr[0])) {
            return;
          }
          select.setAttribute(attr[0], attr[1]);
        });
        for(let i = 0; i < field.options.length; i++) {
          const option = document.createElement('option');
          option.setAttribute("label", field.options[i].text);
          option.setAttribute("value", field.options[i].value);
          if(field.options[i].disabled){
            option.setAttribute("disabled", "true");
          }
          if(field.options[i].selected){
            option.setAttribute("selected", "true");
          }
          select.appendChild(option);
        }
        div.appendChild(select);
      } else {
        const input = document.createElement('input');
        if (field.type === "checkbox") {
          input.classList.add('sigma-input-checkbox');
          div.classList.add('sigma-checkbox-div');
        } else {
          input.classList.add('sigma-input');
        }
        const customClasses = (field.class || "").split(" ").filter(value => value !== '');
        if (customClasses.length > 0) {
          input.classList.add(...customClasses);
        }
        Object.entries(field).forEach(attr => {
          if (['class', 'label', 'id', 'style', 'maskOptions'].includes(attr[0])) {
            return;
          }
          input.setAttribute(attr[0], attr[1]);
          if (field.maskOptions) {
            IMask(input, field.maskOptions);
          }
        });
        input.setAttribute('id', `sigma-input-field-${index}`);
        div.appendChild(input);
      }

    });

    const divText = document.createElement('div');
    divText.classList.add('sigma-footer');
    divText.classList.add('sigma-label');
    divText.innerHTML = SigmaChat.#options.footer
    form.appendChild(divText)

    const divButton = document.createElement('div');
    form.appendChild(divButton);

    const button = document.createElement('button');
    button.classList.add('sigma-btn');
    button.setAttribute("type", "submit");
    button.innerText = SigmaChat.#options.lang.submit;
    divButton.appendChild(button);
  }

  static #connectSocket() {
    window.Echo.connector.pusher.connection.strategy.transports.wss.transport.manager.livesLeft = Infinity;
    Echo.connect();
    Echo.channel(`chat.${this.#clientId}`)
      .listen('NewMessage', (e) => {
        this.#renderMessage({ system: true, ...e.message });
        var now = moment().toISOString();
        const formData = new FormData();
        formData.append('event', 'acknowledge');
        formData.append('datetime', now > (e.message.datetime ?? '') ? now : e.message.datetime);
        formData.append('clientId', this.#clientId);
        const options = {
          method: 'POST',
          body: formData
        }
        fetch(process.env.MIX_PUSHER_HOST + '/chat/sigmaV2/callback/' + SigmaChat.#options.id, options);
        if(SigmaChat.#minimized){
          SigmaChat.#unseenMessages++;
          SigmaChat.#updateBadge();
        }
      })
      .listen('StatusEvent', (e) => {
        this.#setStatus(e.event.id, e.event.status);
      })
      .listen('ConversationFile', (e) => {
        // console.log(e.message);
        var msg = document.getElementById(`sigma-message-${e.message.id}`);
        if(msg){
          msg.querySelectorAll(`*:not([src^="${process.env.MIX_PUSHER_HOST}/dist/img/file.png"])`).forEach(element => {
            element.setAttribute('src', e.message.filename);
          });
          msg.querySelectorAll('[href]').forEach(element => {
            element.setAttribute('href', e.message.filename);
          });
        }
      })
      .listen('ChatEscalated', (e) => {
        this.#atChat = true;
      })
      .listen('ChatTerminated', (e) => {
        setTimeout(() => {
          this.#atChat = false;
          document.getElementById('sigma-component-send-message').style.display = 'none';
          if(SigmaChat.#options.newChat){
            document.getElementById('sigma-component-new-chat').style.display = 'flex';
          }
          var messages = document.querySelector('.sigma-messages');
          if(messages && !messages.querySelector('sigma-terminated-chat')){
            const div = document.createElement('div');
            div.classList.add('sigma-terminated-chat');
            div.innerHTML = `
              <hr class="sigma-terminated-chat-division">
              <span class="sigma-terminated-chat-text">${SigmaChat.#options.lang.terminated}</span>
            `;
            messages.appendChild(div);
            messages.scrollTop = messages.scrollHeight;
          }
        }, 1000);
      })
      .listen('ChatTerminatedWithSurvey', (e) => {
        this.#atChat = false;
        this.#atSurvey = true;
      })
      .listen('ChatHistory', (e) => {
        e.history.events.forEach(message => {
          switch (message.type) {
            case 'message':
              this.#renderMessage({ system: true, ...message.data }, true);
              break;
            case 'escalated':
              this.#atChat = true;
              break;
            case 'terminated':
              this.#atChat = false;
              document.getElementById('sigma-component-send-message').style.display = 'none';
              if(SigmaChat.#options.newChat){
                document.getElementById('sigma-component-new-chat').style.display = 'flex';
              }
              var messages = document.querySelector('.sigma-messages');
              if(messages && !messages.querySelector('sigma-terminated-chat')){
                const div = document.createElement('div');
                div.classList.add('sigma-terminated-chat');
                div.innerHTML = `
                  <hr class="sigma-terminated-chat-division">
                  <span class="sigma-terminated-chat-text">${SigmaChat.#options.lang.terminated}</span>
                `;
                messages.appendChild(div);
              }
              break;
            case 'terminatedWithSurvey':
              this.#atChat = false;
              this.#atSurvey = true;
              break;
          }
        });
        const formData = new FormData();
        formData.append('event', 'acknowledge');
        formData.append('datetime', moment().toISOString());
        formData.append('clientId', this.#clientId);
        const options = {
          method: 'POST',
          body: formData
        }
        fetch(process.env.MIX_PUSHER_HOST + '/chat/sigmaV2/callback/' + SigmaChat.#options.id, options);
        if(SigmaChat.#minimized){
          SigmaChat.#unseenMessages++;
          SigmaChat.#updateBadge();
        }
      });
  }

  static #startChat(history = false, minimized = false) {
    this.#atChat = false;
    this.#atSurvey = false;
    if(this.#user == null || this.#user == undefined){
      this.#fromForm = true;
      const myForm = document.getElementById('sigma-chat');
      if (!myForm) {
        return;
      }
      const formData = new FormData(myForm);
      this.#user = Object.fromEntries(formData);
      if (this.#user.number1 && this.#options.phone.code) {
        const number = document.querySelector('input[type="tel"]');
        const mask = IMask(number, this.#options.phone.maskOptions);
        this.#user.number1 = this.#options.phone.code + mask.unmaskedValue;
      }
    }

    let root = document.querySelector('body div.sigma-container')
    if (!root) {
      root = document.createElement('div');
      root.classList.add('sigma-container');
      document.body.appendChild(root);
    }
    root.replaceChildren();
    root.style.display = minimized ? 'none' : 'block';

    const header = document.createElement('div');
    header.classList.add('sigma-header');
    root.appendChild(header);

    if(SigmaChat.#options?.icon?.src){

      const headerIcon = document.createElement('div');
      headerIcon.classList.add('sigma-header-icon');
      header.appendChild(headerIcon);

      const img = document.createElement('img');
      img.classList.add('sigma-img-icon');
      img.setAttribute('src', SigmaChat.#options.icon.src);
      headerIcon.appendChild(img);
    }

    if(SigmaChat.#options.title){
      const headerTitle = document.createElement('div');
      headerTitle.classList.add('sigma-header-title');
      headerTitle.innerHTML = SigmaChat.#options.title;
      header.appendChild(headerTitle);
    }

    const headerButtons = document.createElement('div');
    headerButtons.classList.add('sigma-header-buttons');
    headerButtons.innerHTML = `
    <a class="sigma-min-chat" onclick="SigmaChat.minMaxChat()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-lg"
        viewBox="0 0 16 16" color="white">
        <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
      </svg>
    </a>
    <a class="sigma-btn-close" onclick="SigmaChat.close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg"
        viewBox="0 0 16 16" style="color: white; margin-right: 0.5vw;">
        <path
          d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z">
        </path>
      </svg>
    </a>
    `;
    header.appendChild(headerButtons);


    const form = document.createElement('form');
    form.classList.add('sigma-form');
    form.setAttribute('onsubmit', 'event.preventDefault();SigmaChat.sendMessage()');
    form.innerHTML = `
      <div id="sigma-close-confirm" class="sigma-center-container" style="z-index: 3">
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 80 80" fill="none" >
          <path d="M33 60H24.3333C23.1841 60 22.0819 59.5317 21.2692 58.6983C20.4565 57.8648 20 56.7343 20 55.5556V24.4444C20 23.2657 20.4565 22.1352 21.2692 21.3017C22.0819 20.4683 23.1841 20 24.3333 20H33" stroke="var(--sigma-primary-color)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M49 51L60 40L49 29" stroke="var(--sigma-primary-color)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M60 40H33" stroke="var(--sigma-primary-color)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="sigma-headline">${SigmaChat.#options.lang.quit}</p>
        <div class="sigma-button-container">
          <button type="button" class="sigma-btn-yes-no" onclick="SigmaChat.continueChat()">${SigmaChat.#options.lang.no}</button>
          <button type="button" class="sigma-btn-yes-no" onclick="SigmaChat.closeChat()">${SigmaChat.#options.lang.yes}</button>
        </div>
      </div>
      <div id="sigma-offline" class="sigma-center-container">
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 80 80" fill="none">
          <path d="M57.5 55.9255C60.9295 51.9083 63 46.696 63 41C63 36.6026 61.7659 32.4935 59.6253 29M51 20.796C47.7324 19.0132 43.9845 18 40 18C27.2975 18 17 28.2975 17 41C17 44.7584 17.9015 48.3063 19.5 51.4391" stroke="var(--sigma-primary-color)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M51.8237 48.5C53.2018 46.332 54 43.7592 54 41C54 39.0475 53.6003 37.1884 52.8783 35.5M44.5 27.7389C43.0877 27.2598 41.5742 27 40 27C32.268 27 26 33.268 26 41C26 42.8399 26.3549 44.5968 27 46.2062" stroke="var(--sigma-primary-color)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          <line x1="60" y1="20.8284" x2="21.8162" y2="59.0122" stroke="var(--sigma-primary-color)" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        <p class="sigma-headline">${SigmaChat.#options.lang.offline}</p>
        <div class="sigma-button-container">
          <button type="button" class="sigma-btn-yes-no" onclick="SigmaChat.connect()">${SigmaChat.#options.lang.retry}</button>
        </div>
      </div>
      <div id="sigma-connecting" class="sigma-center-container" style="display: flex;">
        <div style="height: 96px; display: flex; align-items: flex-end;">
          <div class="sigma-loader"></div>
        </div>
        <p class="sigma-headline">${SigmaChat.#options.lang.connecting}</p>
      </div>
    `;
    root.appendChild(form);

    const div = document.createElement('div');
    div.classList.add('sigma-messages');
    const divbg = document.createElement('div');
    divbg.classList.add('sigma-messages-bg', 'd-none');
    form.appendChild(div);
    form.appendChild(divbg);

    const divSendMessage = document.createElement('div');
    divSendMessage.setAttribute('id', 'sigma-component-send-message');
    divSendMessage.classList.add('sigma-component-send-message')
    form.appendChild(divSendMessage)

    const divNewChat = document.createElement('div');
    divNewChat.setAttribute('id', 'sigma-component-new-chat');
    divNewChat.classList.add('sigma-component-new-chat');
    divNewChat.style.display = 'none';
    divNewChat.style.justifyContent = 'center';
    divNewChat.innerHTML = `
      <button type="button" class="sigma-btn" onclick="SigmaChat.showChat()">${SigmaChat.#options.lang.newChat}</button>
    `;

    form.appendChild(divNewChat);

    if(SigmaChat.#options.canSendFiles){

      const labelAttachment = document.createElement('label');
      labelAttachment.classList.add('sigma-label-attachment');
      labelAttachment.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
          <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
        </svg>
      `
      divSendMessage.appendChild(labelAttachment)

      const upload = document.createElement('input');
      upload.classList.add('sigma-input');
      upload.setAttribute("type", "file");
      upload.setAttribute("name", "file");
      upload.setAttribute("id", "sigma-file-msg");
      upload.addEventListener("change", function(e) {

        var el = document.querySelector("#sigma-file-msg").files[0].name;
        var input = document.querySelector("#sigma-input-msg");

        var iconAttachment = document.querySelector(".sigma-label-attachment");

        if (el != '') {
          input.style.marginLeft = '1.5rem';
          input.setAttribute("readOnly", "true");
          input.value = el;

          document.querySelector("#sigma-file-msg").style.display = "none";

          iconAttachment.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-file-excel" viewBox="0 0 16 16">
            <title>Cancelar Envio</title>
            <path d="M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z"/>
            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
          </svg>`;

          iconAttachment.style.display = "block";

          var deleteAttachment = document.querySelector(".bi-file-excel");
          deleteAttachment.addEventListener("click", function(e) {

            var getInput = document.querySelector("#sigma-input-msg");
            getInput.removeAttribute("readOnly");
            getInput.style.marginLeft = '0.5rem'
            input.value = '';

            var teste = document.querySelector(".sigma-input")
            teste.value = ''

            iconAttachment.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
            </svg>`;


            document.querySelector("#sigma-file-msg").style.display = "block";
            iconAttachment.style.marginRight = "0";
            input.style.margiLeft = '.5rem';
          })

        }

      })
      divSendMessage.appendChild(upload);
    }

    const inputMessage = document.createElement('textarea');
    inputMessage.classList.add('sigma-input');
    inputMessage.setAttribute("name", "message");
    inputMessage.setAttribute("cols", "33");
    inputMessage.setAttribute("rows", "1");
    inputMessage.setAttribute("placeholder", SigmaChat.#options.lang.messagePH);
    inputMessage.setAttribute("autocomplete", 'off');
    inputMessage.setAttribute("id", "sigma-input-msg");
    divSendMessage.appendChild(inputMessage);

    // Função para ajustar a altura do textarea
    function adjustTextareaHeight(textarea) {
      textarea.style.height = 'auto'; // Reseta a altura para recalcular
      textarea.style.height = textarea.scrollHeight + 'px'; // Ajusta a altura para o scrollHeight

      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10);
      const maxHeight = lineHeight * 5; // Define a altura máxima para 5 linhas

      if (textarea.scrollHeight > maxHeight) {
          textarea.style.height = maxHeight + 'px';
          textarea.style.overflowY = 'auto'; // Mostra a barra de rolagem se exceder o máximo
      } else {
          textarea.style.overflowY = 'hidden'; // Esconde a barra de rolagem se abaixo do máximo
      }

    }

    // Adiciona o evento input para ajustar a altura dinamicamente
    inputMessage.addEventListener('input', function() {
      adjustTextareaHeight(this);
    });

    inputMessage.addEventListener('keypress', function(event) {
      if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault(); // Evita que a tecla Enter insira uma nova linha
          SigmaChat.sendMessage(); // Chama a função de envio de mensagem
      }
    });

    const divInput = document.createElement('div');
    divInput.classList.add('sigma-send-message');
    divSendMessage.appendChild(divInput);

    var sendMessage = document.querySelector('.sigma-send-message')

    const divButton = document.createElement('div');
    sendMessage.appendChild(divButton);

    const button = document.createElement('button');
    button.classList.add('sigma-btn', 'sigma-send-msg-btn');
    button.setAttribute("type", "submit");
    button.setAttribute("id", "sigma-btn-send");
    if(SigmaChat.#options.canSendAudio) {
      button.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-mic"
        viewBox="0 0 16 16">
        <path
          d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
        <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
      </svg>
      `;
    } else {
      button.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send"
          viewBox="0 0 16 16">
          <path
            d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
        </svg>
      `;
    }
    button.addEventListener("click", function(event) {
      var el = document.querySelector("#sigma-input-msg");
      el.removeAttribute("readOnly");
      el.setAttribute("value", "");
      el.style.marginLeft = '.5rem';


      var iconAttachment = document.querySelector(".sigma-label-attachment");

      iconAttachment.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
      <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
      </svg>`;

      document.querySelector("#sigma-file-msg").style.display = "block";

    })
    divButton.appendChild(button);

    if(!history){
      this.#clientId = uuidv4()
    }

    if(SigmaChat.#state == 'connected'){
      Echo.disconnect();
      if(SigmaChat.#healthCheck){
        clearInterval(SigmaChat.#healthCheck);
        SigmaChat.#healthCheck = null;
        SigmaChat.#state = 'disconected';
      }
    }
    SigmaChat.#connectSocket();
    SigmaChat.#checkStatus();

    if(sessionStorage && SigmaChat.#options.keepHistory && !history){
      sessionStorage.setItem('sigma-chat-history', JSON.stringify({
        user: SigmaChat.#user,
        fromForm: SigmaChat.#fromForm,
        clientId: SigmaChat.#clientId,
        minimized: false,
        lastModified: moment().toISOString(),
        messages: []
      }));
    }

    if(SigmaChat.#options.autoMessage && !history){
      const input = document.querySelector('#sigma-input-msg');
      input.value = SigmaChat.#options.autoMessage;
      this.sendMessage()
    }
    if(SigmaChat.#options.confirmMessage && !history){
      this.#renderMessage({
        message: SigmaChat.#options.confirmMessage?.message || "",
        name: SigmaChat.#options.confirmMessage?.name || "",
        buttons: SigmaChat.#options.confirmMessage?.buttons,
        system: true });
    }
  }

  static #updateBadge(){
    if(SigmaChat.#unseenMessages > 0){
      document.querySelector('.sigma__bg-number-of-messages').style.display = 'block';
      document.querySelector('.sigma__number-of-messages').innerText = SigmaChat.#unseenMessages > 9 ? '9+' : SigmaChat.#unseenMessages;
      return;
    }
    document.querySelector('.sigma__bg-number-of-messages').style.display = 'none';
  }

  static #renderMessage(message, history = false) {
    if(!message.datetime){
      message.datetime = moment().toISOString();
    }
    if(sessionStorage && SigmaChat.#options.keepHistory && !history){
      const hist = JSON.parse(sessionStorage.getItem('sigma-chat-history'));
      if(hist){
        hist.messages.push(message);
        hist.lastModified = moment().toISOString();
        sessionStorage.setItem('sigma-chat-history', JSON.stringify(hist));
      }
    }
    const direction = message.system ? 'incoming' : 'outcoming';
    const div = document.createElement('div');
    div.setAttribute('id', `sigma-message-${message.id}`);
    if (direction === 'incoming') {
      div.classList.add('sigma-assistent-message');
    }
    let content = '';
    let type = 'text';
    let options = SigmaChat.#options.canReact || SigmaChat.#options.canReply ? `
    <div class="sigma-message-options" style="display: none;">
      <div class="sigma-option-emotes" onclick="showEmotions()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-emoji-laughing" viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
          <path d="M12.331 9.5a1 1 0 0 1 0 1A4.998 4.998 0 0 1 8 13a4.998 4.998 0 0 1-4.33-2.5A1 1 0 0 1 4.535 9h6.93a1 1 0 0 1 .866.5zM7 6.5c0 .828-.448 0-1 0s-1 .828-1 0S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 0-1 0s-1 .828-1 0S9.448 5 10 5s1 .672 1 1.5z"></path>
        </svg>
      </div>
      <div class="sigma-option-reply" onclick="sigmaReplyMessage()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"></path>
        </svg>
      </div>
    </div>
    <div class="sigma-emotions" style="display: none;">
      <div>
        <div class="class-sigma-emotion__happy" onclick="sendEmotion('happy')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 2000 2000">
            <image id="Camada_1" data-name="Camada 1" class="cls-1" width="2002" height="2002" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAEkUlEQVQ4jX1US4wUVRQ99326umt6PnY3M8MQIsEwCeGTGUJw4QbCAjTKwoEFwY3IwgTNQFTcqERdGD8BidEVhg0aAkJMJApGFkRDAF2gEYmKoskwM8y3/91T9d69pmpm7a3c5Naruufd9865l1oPfgAggMqB4jFIXIZwFYju71Jubif5+hCJGwAlT2ZcyNwSU7jMtvdLsstBcIh1L6C6AYmxBOgBUwLcNFTlytNo332LfHkdJVuRBcggNXGA+MVQhXcQbjjKucFzTvUAFKTfVFIcKA9JCqheeU9Vr54nP78OKkySgAQQtOhJrLKpEzfWqurVs1S/fkxUB1IHwSh2EBVAzZ57H3Pfvmy6B4BSBlAA5jyimoD0UoEeyOQJKGhAuoCZNqRy87BVPeS7nzxM8KDqg2swzV922OnTl8yyEqK64ZPn63G9znhuJG+KK42Op3x6kEyvwvx9zye/aPgwJBzYnddBp1duagq+tPcp7th8UWmOoOvXTukwwFzV8JET5daDMgt1KHn1o0r7z79iNt0E20X4+57jIycqkWQJ0zWWV46X45l5LbojB2rcOMUSQ79xcPhx3fjxeV0s4vur7bjRFnntnULuse2hmfo95vExx0NbsoYs4euLTV8qaBo9WrBbt+f0rzcW2DWE1mzsVFKdCpUOblH7txc/Ve3b+2EKiGORMEdwLJTcl80Q2m1hraASTryDZHNEcSwgBRhFaLZErCWCKwPB4GlDfnYICUNOEHYQIUcw0aI0YQnZHClEkpJiM0TJutVLxGcIoQJFzWSDHMDV9YYkGkjkkEit1RRxVfZKgRYh/t+EIcaQNgYEsQC3iwaSChG2V+P6dy0+fLzC/UWlFBEiMFXBWACnBWeh0AmFAEpYgIkZLx+MdvttO3MmnnBp1UZUboJ8uR8cotRJNDPHmZmWx6x1KEFjEFlZTSY94bQ4/IEFmkaMkrNwdUpyGJwcJAaoc87ALr9F0diwzGexdijA5k3WX/6ppY6s6vVPcBetRkB56BSwBsY9LPAlVZF3/53WW4cD3jAckMwz4BuQzMO3jWQGLkiDnnWRwIakXtqXj0Zu9vHeSsGix6HsPcpY7F8LwiYdYlO5C4/4jmjFM3VBHoErJ00n4Oya89QY/wZm9rNJiif6YHpgV5DgQs6NvR0oMaJtPwNLrZfgxpMKiIhXvr7gsaet4zFR5MpgW5x1hX0lRRRCwi37iRcA7+EmQTJSM4VjVQ42OMdVYj+pJPEkDta7uHis5mVPzbgJUYk4wU1wfvsBsStA7YkbgC6CKmc/pMpXo9B9gDbQyz24Rhz/bMRPLpao+z3sRgfVJdpP6FTpxDPw+W2fuJ6Rg8rXEpaTcWQhXTsOKTcOat4ZBTLwYyEoCxU8GgM2WhRyROCKgh9LXprpfOTs2o+lZ9cL2nSmTJv0YriRzDdwx+ZD0Muu6/adN+Erg9IG/EIyYJdaQzwoWUxMdd71ucGjkln5ueLW4mwjmwAmlvwcgXwdHKw6w7bvDEX/7Iab2gluryOJe1Nlq2Ca1bLbYh+6JKb/HOsQittpbtqrEPwHD7xIhN7FrvQAAAAASUVORK5CYII="></image>
          </svg>
        </div>
        <div class="class-sigma-emotion__furious" onclick="sendEmotion('furious')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 2000 2000">
            <image id="Camada_2" data-name="Camada 2" x="6" y="8" width="1988" height="1984" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAEEUlEQVQ4jU2US4gdRRSGv1PVj9v3kcwkcWY0E42gCBLDgGajLsQoZBFUFFFQERI3GhUScDO4EELcuBAfcakgEUQFzTIrIYqKaIyGCD4gCRpJMjPGce6zu6qOVN+bYDVFV1dV/+c/5/znyN+L+8AapDfCrCkhZGi/QpPGbimrXTocLQCbUQD5iyz9UfLimGm3j2rvHzAldsv1IBZCIOH/QwV8eEAoDzIabo8XJDcRiBpQdSvV6E513WdD1f0ZzCtS2I+RyR2EBA1o/IibfnDQoC9rIx5FEDu2pjImGA0Gg4jGu7dqpR8Rmq+LcoAkgob4akCw4FcPkVeLEqnX1hQJTIC03pOgkAhaRmyLbSs66O/Xy39ip6cP4BzGZxGgvDsxw0VpWWgKUoBpAAVIZFt/K9KKJBQtI1NUR6LSNmi5st+tLt9fVSOSzA7Arx2hEbBiJ6HSmppEF2uGcT32GCOIB3VgWmAKg/qEIIMjmtnZRAq3U3A3KCYmm7xhcQPP0GkdqxpIJ6DxaQbIIAsi+UbLsOtxTrChmpGketCYzD0qeaCYthxf6fH2yWVMR2jPJkgG5BO348xDTbVzXUI+Jxz+aYnPV3o019v63OTuESNJtSCF4K1n+/UZ0lIOfXeJc90hzfmEZgdIA5oHGm2hNZ9xvl/y6ollfENY2NLAJQEaBpP62wyZm6MhOOuZ6Rj2PTRbx3Hra7/xxhdLXFRHth6yjrAsjne+WWL+zV9YrRwv7p7j2o7gEocUCrnfmEijFkYdqzIE0n9HPHFHhzJu2MCplSH33tyqhXT6/BAvnsWdm3hyW4fQHVAmAZNMMqeKDD95/FuUHVeqQZ3SKAy0EqgUv+aoqnHWU2uwUVpRxF3PsO8QY8ZijRo15mRCkZ6SarADmVRhBiUBemXNOt1gsWn8YWwwrDqqUUy5jHU5kVjNDj2dSGf9Z9Lr7lGxV12PGsvXGbDC1yf6nFt2VAGuaRl23d4kTw2jy2FcwkwEGhzeFkdl8P1LmN7Zi+LLGSSvLdmG4cwFx6df9ikK4ca5hDQRzl5wXFr2PHxXm1s2J7jeuCQJFUi25pvzU0nIc7CzTyX9M8c0RjdaayrdgWPhpoT77mmN3fJRk4avjvfpDqtaJvhJInyJtrbssY3pIMPfD6O2gen/8JYZ/Pq82jbqhKxtIBXcZT/uRpPAp+ssOCjXossRbITPZt7zxdY9kWmiSWTYIRTzLxDOGwn+OcmEqgLizK90m/GoqljIsYYj5UAw7Xe92bQ3qEdwxMqGMEJ8Rcg27QvJ1NNgz9b79ZyA1X0irj2IQ9P0D19seMbnU3vxftzaNDbYqyM2P4ea/P2QZh9IKB8jDHdB2CYaZsZ+2yUlPY1Jj4Vs6kM1UkrZR8ddE4D/AGn89Vw55o2yAAAAAElFTkSuQmCC"></image>
          </svg>
        </div>
        <div class="class-sigma-emotion__heart" onclick="sendEmotion('heart')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 2000 2000">
            <image id="Camada_4" data-name="Camada 4" x="10" y="10" width="1980" height="1980" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAELUlEQVQ4jXWU3W9URRjGn3fO2bNn2223WLof3W6JhW0NWGJriPFC60dF0xgDUv4BTQGttHgFFwYt0fhx1ZgYiOFCrkhEKNwYUKHBVmxRlFJASCwFu2y3pV3aZT+652PGzDktitRJJntmZ97fPO8z7wyJ34YBnw6RSMG+dh2ojYGyWdDV0VcpNfUKCvknkM9FiQjC40kiUHFJRCKn7LrVx6F6oc6kwR+vB62JAbkCXKCuQUzPwZ64DUolNrEL5/fR9FQjhAA8GuD1wvm2LKC4AAiAh0JXsbbxA9TWHRGrY6BYEMgvgMHmgKZDBMrAfhn6WDlxtI9SqUb4y+B0CSMCGAM8Hve/8nKw6em17IeTX2PoXK/w+0E+HwgCql3ig1jxCJS+vk9o4MxuRGsAzoHEhIQIyMYYOb+2LRy4bRPCEcDjBxse7MaqMLM6t3fJTMmYvAUaHGxVP/v0e4TCQDYLGEVuvraZW03NJNP0nO3nxDmZLS+Q0DSooyPCc/wYg8IYygNAMglz585NoqXlBJnnB6D0fp6gmzeiMhXkcrzw/odW8cWXVJmoFEYAh+uiIscc4N6Bs3bJe3sU6DpDPg8Riabt3Xsqlb1PNm9U+k+/jaogcDshjLfesQuvb9XUwgJRIQ8qFkFCEFkWo7w7ZrZN5po4Y7puq9+dZAhHiJIJnwiHR5ny51i7449hACuDwnz2ecWRZRruYchumm5fGluWlErmM88xRKoFFhYARQG7cnkLQzLRhLJyaTSg65AeOSdA7jks25bmNI3g87mxkpGabGRUyEec0pA9mSTlxpg8Y/ek/69x7pjKbo4JTPxFUgikEMOoZI7VsimKhJL34AEw07R4ILA8VMICATCbW/qBLwQUlaCqrmohwITum3L8k+BwGGx0RC3t7BBk2w9DF2GyEku7doBd+FVFJOLGFosQXj3NUBW6iFzW3UEGr6qD8tOAp3THmw9C/w3r3CaU/tMqHq1z52RsZh4iGr3CEI8flXfT2cVJXwDxx6Cc+wcqAgGI/8LqG1zVS5ZJcHXNMWasX/+tqK1NYTbt3tdloBywuAPreBgmY9KzQE1sjm/Y8A0TVZWwWlvfQCHv1uKiufehwz97/Pv2Cv9HPVwZ+PFBmFwrY7I5iPat26ipiZMxfg0iFIKyf3+v8tWhbsRi7quyFCAVTCbdcXXUTW1pTj5niQnYm7cc5Lve7aCZWahU6gP5NIj29l18/Baxi793QVt8pqRQWbTBkKtKfksnJSx7DzBM4KmnvzTb2rZbmTlQ5i5UZ9G9DCiTgb3x5W6srBqiSyM9dDcdd66M5gVUj8O5fwWlq4GKcd7U3EMN6w45L3wxAFJVqM7OxJw6ork0EG84bMdih+n6H+10Z6aN8rl1VCwG5SpRUnIHmvcyrwqeQn3DEVpRYWN+HigvcRkA/gbVN/jG3J86aAAAAABJRU5ErkJggg=="></image>
          </svg>
        </div>
      </div>
      <div>
        <div class="class-sigma-emotion__surprise" onclick="sendEmotion('surprise')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 2000 2000">
            <image id="Camada_3" data-name="Camada 3" x="6" y="6" width="1988" height="1988" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAEZ0lEQVQ4jVWVWWhcZRTHf+f77p2ZLJNk0iRNm1i3oKFxiSIilhYVo1XR4oNviogvUnF9EESlLo+CUqr1TV8UsWJMRbG4YxWMDzZVE1OlrkljEzMznSWZuct35M6k2hw4L/ec8z/b/55PKkvHQNIQL2KCWRQPiQqYYO52CZdvFF0ZResDACqpeTUdRzXVd4j02e+KtCNBGde+FUl1ggtZA/TBlSH6FVudvSPD1LPYyrBWYqLIA5uiIXGIbwPICrie2UBH9qg/dEBbz0f8FLgYr5HZtIEofvHwC1KdeuSnvwz5Sgtbz0vT0SFJ4oaYTJpCyWdmOqSnfWH43C1zb5n08tVxdvRhjAUtYYz1MV4Gvzj+ol/96pFKrY1Q2+npMpRXHBjhPxGorGjDFpClWu/A1r5+yBQP7lWXReMUxgRVvPzELbYw8XDk+unuy3DJSEbLpaLrba9ijIcqTXXKWVt8RWMuGAjJ9WSIXS+m8M6DcurQrZDGEJ1ASh++piaN35mhUHJ67V3T+uSBHXpk6ZqoWlnE82hq1vDcS0uy7b7466df37R3uVjHy6ZR04Ipf/iqBr9gCH+9jWixF9uFtMLJuYgvvlP55bdF++YHJ8SIr8YKiRLBR5MBy/kg9/6XxXP+ORE3YrA5CBZ6pDa9yz513/DjEs6PYtqh7ujZZDl/c4fOzszoPTeckpEr+k1UjpIFIq3ChZvS+tff5b47x2rDO8c6cRWHqiBuFWx7KPUf7/5ewqWLMS2oA+uD7bI0phYhVBx02+Za8jG0G/CSRSlx0SVMQgzg6uB1TXkSl/oxrQ2HxOCiZqBNdpoAtRmdmqwpKoxelhJ8EZZj4gTDrYE1OJXwsN7tNbiArqNGAmo3WpbmI/fo80Wd/CFocOfKi1L6wmNd2jfgGXcyhrXC/xfFqGn5Gw3WffbahLDqdPczBQ5+XrM9Xdb0dBlz8Iua2f1MQYOq08RnPVY92XbeYDdMSVxtlna6yG7DJ5+tcvj7QC4b9vE98D3h8mGfw0cD+8mnqyobzBlogsQr4G2YNi5z9ji49W17wvGFWJPByRmFmDWM4wtJu2dWmMTGuMwF40bbtk1oavAfonwS0rQ72JgzyXlhXWPaDG3Y3H9pIC6g/mBeWi4ZN2pzRB3X3Su6SnOWAkXH9dtbGBqw7tifEUmVif78Z8TQZuvGdmSEYtz01Tq4FeLcrntd+lwMrobL7nwv7ty1X8IF0JCwpOQGrdn3RE639Ft37PeI2T8iHdxo3b4nc5ob9CQs0fAlOolmb36FzPYJqeeRYGkWtR0kLGZp38umPLmb5JyZVvx+S3kuch9/U290PnZVWrNn+SZciMBVQWto2+X76X7gfjS5qacSwBmQxp9BFM4hK0fuNJVv90hcGkrm5HdlIJccWIFCnai4ihpBve7jcevFeySz9Q2TugjIAMFpwAS9Qhz8TnJ1qP2MBPN32Lh8E648ggZ9a0/AIqZzWr22Q5oZetvZXjVxFZO+FEwOtM6/S3ISzETq7W8AAAAASUVORK5CYII="></image>
          </svg>
        </div>
        <div class="class-sigma-emotion__sad" onclick="sendEmotion('sad')">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 2000 2000">
            <image id="Camada_5" data-name="Camada 5" x="6" y="6" width="1988" height="1988" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAEJklEQVQ4jYWUWWxUZRTHf+e736ztTOlMWSyLFESEUFBAFFEiCQmJQuwDETBqY3wwmljUxBg14cnlwURC0cRISPRBJLwoiws+mLgSnxRMSNkKYZFCO0tnOkvv8n3mTgumLPG83XvP/Z9z/v//OTKSP4sFHLFgBIhhTcGR6pGnVK3vcfGHlkhQnkYYqnnARCYfNfEF39jkoj3EFgZ4JaxWoARMlDFAG+DoFFaacQpfb5Hhb99V3oUOrMU6zaCSDTxMDTEjYH1MdNZZm9n8tp/e8CVUgDKYBDKSO9X4STkpnKuf7lD5L3pEpbE6A4QdWyaGNJ7Ez4Ep4Wee7fWn9WwVM4x4AdrqLERaUQM7e52h3S/b2FysxMJ2bgHG9XdWt4FtRud29Qgaf8pLWy1FpFKxqOEfu/Sl17+ykXaQ+G2AbhUhb3XEu4g388Mu27Jmv1SLeaXP9+SpnWuJtLZDGghsqBKUDW7ZIs54bwFEUwIp9V9OCbzCP5C8s+jP6M1qKj9vltETLZKZzel+OHP8IvOaSvRXkrTPm8X8+QpTNQ1AJyUc7xPOn7rA3c1l+qtNdCyYxew5d2CLpydR/mmTVpW/NoFCIkKtfoWP+1fyS2QxS0ZP88H0X1E6ixkfVrQwUrtCb//DHIkuYrl3ku0dv6N0G4EonNqfT2pxzy0OFfVzPp2dRTbeu56Dg4+yLtvH/fxAkG8BPTaznw9YsaLMxgee4LvBVazLHGMRhwnyk7DOJMS90KklKLWFPhMFVCOURoYavFTdAUg6OEmFk3XGjFsyUNIMVwYbtqu6VyCtQYdWSoAZmayv+erG8AOgTeHmLZ99UqJUtnStTXDXUgdz6va6K+ukBjHVmz6kkoJbMLz1UYlC1ZKaoXlnd4kzv9WZPlnd5CwxVUIsLZGZR6n3daCaJiTMmOJwaEeZRCTBG+9nG968JyPs33Oe1m2h5BPgIChimx/8WwdNy/fp0vddN1r5asGwfFmMpdkmbL+HrRhWLY4xxUlyoBDckG3BGkzivn0qaFm318YXFsW7HDLAtTUZKgYsfCjO7DkarxjgG9BaWLA6QTwSdnSdNfCvYuPzCpJYtlcZFbNe9uluGlekFiZ4YVpEj6kaVGzDfyLg+RaKZqyijDEXrl6ogZ95vjuITLdK3DxBeu2BoO2Fnbj9YOvR8dLB/y61HVW4ZzCZ53ZIes1Bx6+gI6JRpk4w9cUe1MmgeK78Cu51M4VLMoH+ECa0JC6S03Vof2a7tHa/5phwsDo6bEIFZQIcbHbbq6tt4cjUs7yXd0fn4g0gkho/sOFlqYK5JLl6lWySy491PPKmza783PrDWAlrK6ReLOIQ4OOgfRcTi/JHJaVyxRNbNsiu9dbLd1pTmxaqaJ30QDTeemy/2304ne3ct6ZltOaXLmPUKDjtYH3+BZIY2wiR2PuSAAAAAElFTkSuQmCC"></image>
          </svg>
        </div>
      </div>
    </div>
    <div class="sigma__show-message-options" onclick="showMessageOptions()" style="display: flex;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
      </svg>
    </div>
    ` : '';
    if (message.attachment){
      options = '';
      type = message.type.split('/')[0];
      type = message.type.split(';')[0] == 'text/html' ? 'html' : type;
      switch(type){
        case 'image':
          var reply = SigmaChat.#options.canReply ? `
            <a class="sigma-message-${direction}-image-download" href="${message.attachment}" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-return-right" viewBox="0 0 16 16">
                <title>Responder</title>
                <path fill-rule="evenodd" d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5z"></path>
              </svg>
            </a>
          ` : '';
          content = `
          <div class="sigma-attachtment-content-options">
            <img class="sigma-message-${direction}-attachment-image" src="${message.attachment}">
            <div class="sigma-message-${direction}-image-download-content">
              <div class="sigma-options-attachment">
                <a class="sigma-message-${direction}-image-download" href="${message.attachment}" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                    <title>Abrir em nova guia</title>
                    <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                    <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                  </svg>
                </a>
                ${reply}
              </div>
            </div>
          </div>
          `;
          break;
        case 'video':
          content = `
          <video class="sigma-message-${direction}-attachment-video" controls>
            <source src="${message.attachment}" type="${message.type}">
            ${SigmaChat.#options.lang.video_not_support}
          </video>
          `;
          break;
        case 'audio':
          content = `
          <div class="sigma-flex-row">
            <div class="sigma-audio-button" onclick="SigmaChat.playPause('${message.id}')">
              <svg class="sigma-btn-play" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"></path>
              </svg>
              <svg class="sigma-btn-pause" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"></path>
              </svg>
            </div>
            <div id="waveform-${message.id}" style="width: 100px;"></div>
            <div class="sigma-time-audio-content">
              <p class="sigma-time-audio"></p>
            </div>
          </div>
          `;
          break;
        case 'html':
          content = `
            <iframe class="sigma-iframe-video" src="${message.attachment}" frameborder="0" allowfullscreen></iframe>
          `;
          break;
        default:
          type = 'document'
          var filename = message.filename || message.attachment.split('/').pop();
          content = `
            <div class="sigma-flex-row">
              <img class="sigma-message-document-img" src="${process.env.MIX_PUSHER_HOST}/dist/img/file.png"/>
              <a class="sigma-message-document-link" href="${message.attachment}" target="_blank" download="${message.message}">${message.message}</a>
            </div>
          `;
          break;
      }
      if(message.text){
        content += `<p id="sigma-message-text-${message.id}" class="sigma-message-${direction}-text-msg">${message.text}</p>`
      }
    } else {
      content = `<p id="sigma-message-text-${message.id}" class="sigma-message-${direction}-text-msg"> ${message.message}</p>`;
    }
    const status = direction === 'incoming' ? '' : `
      <div class="sigma-status-message">
        <svg class="sigma__status-sending" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16" style="display: none;">
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
        </svg>
        <svg class="sigma__status-sent" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style="display: none;">
          <title>Enviado</title>
          <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
        </svg>
        <svg class="sigma__status-delivered" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style="display: none;">
          <title>Entregue</title>
          <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"></path>
          <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"></path>
        </svg>
        <svg class="sigma__status-read" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style="display: none;">
          <title>Visualizado</title>
          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
        </svg>
        <svg class="sigma__status-failed" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style="display: none;">
          <title>Erro ao enviar mensagem</title>
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"></path>
        </svg>
      </div>
    `;
    var buttons = '';
    if (message.buttons && Array.isArray(message.buttons)) {
      buttons = `
          <div class='sigma-options-incoming sigma-show-div'>
              <div class='sigma-btn-options'>
              `
      if(message.buttons.length <= 3 && !SigmaChat.#options.menuList){
        for(var i = 0; i < message.buttons.length; i++){
          if(message.buttons[i].value.startsWith("url:")){
            buttons += `<a href="${message.buttons[i].value.substring(4)}" target="_blank"><button type="button" class='sigma-btn-message'>${SigmaChat.#encodeHTML(message.buttons[i].label)}</button></a>`
          } else {
            buttons += `<button type="button" onclick="SigmaChat.sendInteractiveMessage('${SigmaChat.#encodeHTML(message.buttons[i].label).replaceAll('&#39;', '#39;').replaceAll('&quot;', 'quot;')}','${SigmaChat.#encodeHTML(message.buttons[i].value)}')" class='sigma-btn-message'>${SigmaChat.#encodeHTML(message.buttons[i].label)}</button>`
          }
        }
      } else {
        document.getElementById('sigma_chat_menu')?.remove();
        document.getElementById('sigma_chat_burger-menu')?.classList?.remove('sigma_chat_burger-menu');
        let oldButtons = document.querySelector('.sigma-list-message');
        oldButtons?.classList.add('sigma-list-message-old');
        oldButtons?.classList.remove('sigma-list-message');
        buttons +=`<div id="sigma_chat_burger-menu" class="sigma_chat_burger-menu">
                <button type="button" class='sigma-list-message'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 10">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
                  </svg> ${ SigmaChat.#options.lang.menuOptions }</button>
                <div id="sigma_chat_menu">
                <div class="col-12" style="color: darkgray; margin-top: 1vh;">${ SigmaChat.#options.lang.menuOptions }</div>
                <span class="col-1 close-menu" style="cursor:pointer; color: darkgray; text-align: justify; font-weight: bold;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="darkgray" class="bi bi-x-lg" viewBox="0 0 16 16" style="color: white; margin-right: 0.5vw;">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z">
                  </path>
                </svg>
                </span>
                <ul class="scroll-bar-chatv2 sigma_chat_options-list">`;

            for(var i = 0; i < message.buttons.length; i++){
                buttons += `<li class="col-12 mt-2 px-3">
                    <button type="button" class='sigma_chat_item-message close-menu-after' onclick="SigmaChat.sendInteractiveMessage('${SigmaChat.#encodeHTML(message.buttons[i].label).replaceAll('&#39;', '#39;').replaceAll('&quot;', 'quot;')}','${SigmaChat.#encodeHTML(message.buttons[i].value)}')">
                        <p class="sigma_chat_btn-tittle">${SigmaChat.#encodeHTML(message.buttons[i].label).length > 72 ? SigmaChat.#encodeHTML(message.buttons[i].label).substring(0, 72) + '...' : SigmaChat.#encodeHTML(message.buttons[i].label) }</p>
                        <p class="sigma_chat_btn-description">${SigmaChat.#encodeHTML(message.buttons[i].description).length > 105 ? SigmaChat.#encodeHTML(message.buttons[i].description).substring(0, 105) + '...' : SigmaChat.#encodeHTML(message.buttons[i].description)}</p>
                    </button>
                </li>`
            }
        buttons += `</ul></div>
        </div>`;
      }
      buttons += `
              </div>
          </div>
        `;
    }
    div.innerHTML = `
      ${options}
      <div class="sigma-message-${direction}-${type}">
        <div class="sigma-mesage-${direction}-${type}-content">
          <p class="sigma-message-${direction}-name"> ${message.name}: </p>
          ${content}

        </div>
        <div class="sigma-time">
          <div class="sigma-message-${direction}-time">
            ${status}
            <p class="sigma-time-message"> ${moment(message.datetime).format(SigmaChat.#options.dateFormat)}
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16" style="display: none;">
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check" viewBox="0 0 16 16" style="display: none;">
                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z">
                </path>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16" style="display: none;">
                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z">
                </path>
              </svg>
            </p>
          </div>
        </div>
      </div>
      <div class="sigma-emotion-message">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 2000 2000">
      <image id="Camada_2" data-name="Camada 2" x="6" y="8" width="1988" height="1984" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAEEUlEQVQ4jU2US4gdRRSGv1PVj9v3kcwkcWY0E42gCBLDgGajLsQoZBFUFFFQERI3GhUScDO4EELcuBAfcakgEUQFzTIrIYqKaIyGCD4gCRpJMjPGce6zu6qOVN+bYDVFV1dV/+c/5/znyN+L+8AapDfCrCkhZGi/QpPGbimrXTocLQCbUQD5iyz9UfLimGm3j2rvHzAldsv1IBZCIOH/QwV8eEAoDzIabo8XJDcRiBpQdSvV6E513WdD1f0ZzCtS2I+RyR2EBA1o/IibfnDQoC9rIx5FEDu2pjImGA0Gg4jGu7dqpR8Rmq+LcoAkgob4akCw4FcPkVeLEqnX1hQJTIC03pOgkAhaRmyLbSs66O/Xy39ip6cP4BzGZxGgvDsxw0VpWWgKUoBpAAVIZFt/K9KKJBQtI1NUR6LSNmi5st+tLt9fVSOSzA7Arx2hEbBiJ6HSmppEF2uGcT32GCOIB3VgWmAKg/qEIIMjmtnZRAq3U3A3KCYmm7xhcQPP0GkdqxpIJ6DxaQbIIAsi+UbLsOtxTrChmpGketCYzD0qeaCYthxf6fH2yWVMR2jPJkgG5BO348xDTbVzXUI+Jxz+aYnPV3o019v63OTuESNJtSCF4K1n+/UZ0lIOfXeJc90hzfmEZgdIA5oHGm2hNZ9xvl/y6ollfENY2NLAJQEaBpP62wyZm6MhOOuZ6Rj2PTRbx3Hra7/xxhdLXFRHth6yjrAsjne+WWL+zV9YrRwv7p7j2o7gEocUCrnfmEijFkYdqzIE0n9HPHFHhzJu2MCplSH33tyqhXT6/BAvnsWdm3hyW4fQHVAmAZNMMqeKDD95/FuUHVeqQZ3SKAy0EqgUv+aoqnHWU2uwUVpRxF3PsO8QY8ZijRo15mRCkZ6SarADmVRhBiUBemXNOt1gsWn8YWwwrDqqUUy5jHU5kVjNDj2dSGf9Z9Lr7lGxV12PGsvXGbDC1yf6nFt2VAGuaRl23d4kTw2jy2FcwkwEGhzeFkdl8P1LmN7Zi+LLGSSvLdmG4cwFx6df9ikK4ca5hDQRzl5wXFr2PHxXm1s2J7jeuCQJFUi25pvzU0nIc7CzTyX9M8c0RjdaayrdgWPhpoT77mmN3fJRk4avjvfpDqtaJvhJInyJtrbssY3pIMPfD6O2gen/8JYZ/Pq82jbqhKxtIBXcZT/uRpPAp+ssOCjXossRbITPZt7zxdY9kWmiSWTYIRTzLxDOGwn+OcmEqgLizK90m/GoqljIsYYj5UAw7Xe92bQ3qEdwxMqGMEJ8Rcg27QvJ1NNgz9b79ZyA1X0irj2IQ9P0D19seMbnU3vxftzaNDbYqyM2P4ea/P2QZh9IKB8jDHdB2CYaZsZ+2yUlPY1Jj4Vs6kM1UkrZR8ddE4D/AGn89Vw55o2yAAAAAElFTkSuQmCC"></image>
    </svg>
  </div>
  `;

    const messages = document.querySelector('.sigma-messages');
    messages.appendChild(div);
    var distIcon = div.querySelector(`.sigma__status-${message.status}`);
    if(distIcon){
      distIcon.style.display = 'block';
    }
    if(buttons){
      const div2 = document.createElement('div');
      div2.classList.add('sigma-assistent-message');
      div2.innerHTML = buttons;
      messages.appendChild(div2);

      var burgerMenu = document.querySelectorAll('.sigma_chat_burger-menu');
      burgerMenu.forEach((element, index) => {
        if(index != (burgerMenu.length - 1)) {
          element.style.display = 'none';
        }
      })
      burgerMenu = burgerMenu[burgerMenu.length - 1];
      var overlay = document.getElementById('sigma_chat_menu');
      var closeMenu = document.querySelector('.close-menu');
      var closeMenuAfter = document.querySelectorAll('.close-menu-after');
      var overlayBg = document.querySelector('.sigma-messages-bg');
      var assistent = document.querySelectorAll('.sigma-assistent-message');
      var oppened = false;
      if(burgerMenu && overlay){
        if(!burgerMenu.classList.contains('close')){
          burgerMenu.addEventListener('click',function() {
            if(oppened === false && overlay != null){
              assistent.forEach(element => {
                element.style.zIndex = '101';
              });
              this.classList.remove("close");
              overlay.classList.add("overlay");
              // setTimeout(() => {
                overlayBg.classList.remove('d-none');
                overlayBg.classList.add('d-flex');
                oppened = true;
              // }, 240);
            }
          });
        }
      }

      if(closeMenu){
        closeMenu.addEventListener('click',function() {
          if(oppened === true){
            assistent.forEach(element => {
              element.style.zIndex = '1';
            });
            overlay.classList.remove("overlay");
            burgerMenu.classList.add("close");
            overlayBg.classList.remove('d-flex');
            overlayBg.classList.add('d-none');
            setTimeout(() => {
              oppened = false;
            }, 300);
          }
        });
      }

      if(closeMenuAfter){
        closeMenuAfter.forEach(function(elem) {
          elem.addEventListener("click", function() {
            if(oppened === true){
              assistent.forEach(element => {
                element.style.zIndex = '1';
              });
              overlay.classList.remove("overlay");
              burgerMenu.classList.add("close");
              overlayBg.classList.remove('d-flex');
              overlayBg.classList.add('d-none');
              let auxBtnList = document.querySelector('.sigma-list-message');
              auxBtnList.setAttribute('disabled', 'true');
              auxBtnList.classList.add('sigma-btn-message-selected', 'sigma_chat_noHover');
              setTimeout(() => {
                oppened = false;
                burgerMenu = null;
                overlay = null;
              }, 300);
            }
          });
        });
      }

      var inputMessage = document.getElementById('sigma-input-msg');
      if(inputMessage){
        inputMessage.disabled = true;
      }
      var btnAttachment = document.getElementById('sigma-file-msg');
      if(btnAttachment){
        btnAttachment.disabled = true;
      }
      var btnSend = document.getElementById('sigma-btn-send');
      if(btnSend){
        btnSend.disabled = true;
      }

    }

    if(SigmaChat.#atSurvey && !SigmaChat.#options.canSendFilesSurvey){
      var btnAttachment = document.getElementById('sigma-file-msg');
      if(btnAttachment){
        btnAttachment.disabled = true;
      }
    }

    if(type === 'audio'){
      this.#audios[message.id] = SigmaWaveSurfer.create({
        container: '#waveform-' + message.id,
        url: message.attachment,
        mediaControls: false,
        width: 100,
        height: 60,
        barWidth: 4,
        barGap: 5,
        barRadius: 10,
        waveColor: document.documentElement.style.getPropertyValue('--sigma-secondary-color'),
        progressColor: document.documentElement.style.getPropertyValue('--sigma-primary-color'),
        cursorColor: 'none',
      });

      /** When the audio is both decoded and can play */
      this.#audios[message.id].on('ready', (duration) => {
        var msg = document.getElementById(`sigma-message-${message.id}`);
        let totalSeconds = duration; // replace with your time in seconds
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = Math.floor(totalSeconds % 60);

        let formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        msg.querySelector('.sigma-time-audio').innerText = formattedTime;
      });
      /** When the audio starts playing */
      this.#audios[message.id].on('play', () => {
        var msg = document.getElementById(`sigma-message-${message.id}`);
        msg.querySelector('.sigma-btn-play').style.display = 'none';
        msg.querySelector('.sigma-btn-pause').style.display = 'block';

      })

      /** When the audio pauses */
      this.#audios[message.id].on('pause', () => {
        var msg = document.getElementById(`sigma-message-${message.id}`);
        msg.querySelector('.sigma-btn-play').style.display = 'block';
        msg.querySelector('.sigma-btn-pause').style.display = 'none';
      })

      /** On audio position change, fires continuously during playback */
      this.#audios[message.id].on('timeupdate', (currentTime) => {
        var msg = document.getElementById(`sigma-message-${message.id}`);
        let totalSeconds = currentTime; // replace with your time in seconds
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = Math.floor(totalSeconds % 60);

        let formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        msg.querySelector('.sigma-time-audio').innerText = formattedTime;
      })
    }
    messages.scrollTop = messages.scrollHeight;

    var el = document.getElementById(`sigma-message-text-${message.id}`);
    if(el){
      linkifyElement(el, {defaultProtocol: 'https', target: '_blank'}, document);
    }
  }

  static #decodeHTML(str) {
    var txt = document.createElement('textarea');
    txt.innerHTML = String(str).replaceAll('#39;', '&#39;').replaceAll('quot;', '&quot;');
    return txt.value;
  }

  static #encodeHTML(str) {
    var txt =  String(str).replaceAll(/[\u00A0-\u9999<>\&]/g, function(i) {
      return '&#'+i.charCodeAt(0)+';';
    }).replaceAll('\'', '&#39;').replaceAll('\"', '&quot;');
    return txt;
  }

  static #setStatus(id, status) {
    var order = {
      'sending': 1,
      'failed': 2,
      'sent': 3,
      'delivered': 4,
      'read': 5,
    }
    var message = document.getElementById(`sigma-message-${id}`);
    if(!message){
      return;
    }
    var currentStatus = message.querySelector('svg[style*="display: block"]')
    var oldStatus = currentStatus.classList[0].substring(14);
    if(order[oldStatus] > order[status]){
      return;
    }
    currentStatus.style.display = 'none';
    message.querySelector(`.sigma__status-${status}`).style.display = 'block';
    if(sessionStorage && SigmaChat.#options.keepHistory) {
      var history = JSON.parse(sessionStorage.getItem('sigma-chat-history'));
      if(history){
        var index = history.messages.findIndex((message) => message.id == id);
        if(index >= 0){
          history.messages[index].status = status;
          sessionStorage.setItem('sigma-chat-history', JSON.stringify(history));
        }
      }
    }
  }

  static sendMessage() {
    const formData = new FormData();
    const input = document.querySelector('#sigma-input-msg');
    const file = document.querySelector('#sigma-file-msg');

    var messageId = uuidv4();
    formData.append('id', messageId);
    formData.append('message', input.value);
    formData.append('clientId', this.#clientId);
    Object.entries(this.#user).forEach((data) => {
      formData.append(`user[${data[0]}]`, data[1]);
    });
    if (file.files.length > 0) {
      var reader = new FileReader();
      const uuid = (file.files[0]?.name ?? input.value) ?? `sigma-attachment-${uuidv4()}.${file.files[0].type.match(/[^\/]+\/(\w+)\.*/i)[1]}`;
      this.#renderMessage({ id: messageId, message: input.value, name: this.#user.firstName, attachment: uuid, type: file.files[0].type, status: 'sending', datetime: moment().toISOString() });
      reader.onload = function (e) {
        const attachment = document.querySelector(`[src="${uuid}"]`);
        const download = document.querySelector(`[href="${uuid}"]`);
        attachment.src = e.target.result;
        download.href = e.target.result;
      };
      reader.readAsDataURL(file.files[0]);
      if(file.files[0].size > (this.#options.fileSize * 1024 * 1024)){
        var messageId2 = uuidv4();
        formData.set('message', SigmaChat.#options.lang.fileSizeSystem);
        formData.set('id', messageId2);
        this.#renderMessage({ id: messageId2, message: SigmaChat.#options.lang.fileSize, system: true, name: 'System', datetime: moment().toISOString() });
        SigmaChat.#setStatus(messageId, 'failed');
        messageId = messageId2;
      } else {
        formData.append('attachment', file.files[0]);
      }
    } else {
      if(!input.value){
        return;
      }
      this.#renderMessage({ id: messageId, message: input.value, name: this.#user.firstName, status: 'sending', datetime: moment().toISOString() });
    }

    const options = {
      method: 'POST',
      body: formData
    }
    input.value = ""
    file.value = ""
    input.style.height = 'auto';
    fetch(process.env.MIX_PUSHER_HOST + '/chat/sigmaV2/callback/' + SigmaChat.#options.id, options)
      .then(response => {
        if(response.ok){
          SigmaChat.#setStatus(messageId, 'sent');
        }else {
          SigmaChat.#setStatus(messageId, 'failed');
        }
      })
      .catch(error => {
        SigmaChat.#setStatus(messageId, 'failed');
      });
  }

  static sendInteractiveMessage(label, value){
    var optionSelected = document.querySelector('.sigma-show-div');
    if(!process.env.MIX_PUSHER_HOST){
      optionSelected.classList.add("sigma-hide");
      optionSelected.classList.remove("sigma-show-div");
    } else {
      var buttons = document.querySelectorAll('button:not(disabled).sigma-btn-message');
      buttons.forEach( button => {
        button.disabled = true;
        button.classList.add('sigma_chat_noHover')
        if(button.innerHTML == label){
          button.classList.add('sigma-btn-message-selected')
        }
      })
    }
    const formData = new FormData();

    var messageId = uuidv4();
    formData.append('id', messageId);
    formData.append('message', SigmaChat.#decodeHTML(label));
    formData.append('button[label]', SigmaChat.#decodeHTML(label));
    formData.append('button[value]', SigmaChat.#decodeHTML(value));
    formData.append('clientId', this.#clientId);
    Object.entries(this.#user).forEach((data) => {
      formData.append(`user[${data[0]}]`, data[1]);
    });

    this.#renderMessage({ id: messageId, message: SigmaChat.#decodeHTML(label), name: this.#user.firstName, status: 'sending', datetime: moment().toISOString() });

    const options = {
      method: 'POST',
      body: formData
    }
    fetch(process.env.MIX_PUSHER_HOST + '/chat/sigmaV2/callback/' + SigmaChat.#options.id, options)
      .then(response => {
        if(response.ok){
          SigmaChat.#setStatus(messageId, 'sent');
        }else {
          SigmaChat.#setStatus(messageId, 'failed');
        }
      })
      .catch(error => {
        SigmaChat.#setStatus(messageId, 'failed');
      });
      var inputMessage = document.getElementById('sigma-input-msg');
      if(inputMessage){
        inputMessage.disabled = false;
      }
      var btnAttachment = document.getElementById('sigma-file-msg');
      if(btnAttachment){
        btnAttachment.disabled = false;
      }
      var btnSend = document.getElementById('sigma-btn-send');
      if(btnSend){
        btnSend.disabled = false;
      }
  }

  static showChat(user = null) {
    if(user){
      this.#user = user;
    }
    if(this.#user == null || this.#user == undefined){
      this.#drawChat();
      if(SigmaChat.#options.assistent == true) {
        const popup = document.querySelector('.sigma-popup');
        popup.remove();
      }
      return;
    }
    this.#startChat();
  }

  static initChat() {
    this.#startChat();
  }

  static minMaxChat() {
    var chat = document.querySelector('.sigma-container');
    var fab = document.querySelector('.sigma-fab');
    var minimized = document.querySelector('.sigma-minimized');
    if(!chat || !minimized){
      return;
    }
    if(sessionStorage && SigmaChat.#options.keepHistory) {
      var history = JSON.parse(sessionStorage.getItem('sigma-chat-history'));
      if(history){
        history.minimized = !history.minimized;
        history.unseen = history.minimized ? SigmaChat.#unseenMessages : 0;
        sessionStorage.setItem('sigma-chat-history', JSON.stringify(history));
      }
    }
    SigmaChat.#minimized = !SigmaChat.#minimized;
    if(chat.style.display == 'none'){
      SigmaChat.#unseenMessages = 0;
      SigmaChat.#updateBadge();
      chat.style.display = 'block';
      minimized.style.display = 'none';
      if(fab){
        fab.style.display = 'block';
      }
      return;
    }
    chat.style.display = 'none';
    minimized.style.display = 'flex';
    if(fab){
      fab.style.display = 'none';
    }

  }

  static playPause(id) {
    if(!this.#audios[id]){
      return;
    }
    if(this.#currentAudio && (this.#currentAudio != id) && this.#audios[this.#currentAudio] && this.#audios[this.#currentAudio].isPlaying()){
      this.#audios[this.#currentAudio].pause();
    }
    this.#currentAudio = id;
    this.#audios[id].playPause();
  }

  static #checkStatus() {
    if(SigmaChat.#healthCheck) {
      return;
    }
    SigmaChat.#healthCheck = setInterval(() => {
      if(SigmaChat.#state === Echo.connector.pusher.connection.state){
        return;
      }
      SigmaChat.#state = Echo.connector.pusher.connection.state;
      switch (SigmaChat.#state){
        case 'connecting':
          document.getElementById('sigma-connecting').style.display = 'flex';
          document.getElementById('sigma-offline').style.display = 'none';
          break;
        case 'connected':
          document.getElementById('sigma-connecting').style.display = 'none';
          document.getElementById('sigma-offline').style.display = 'none';
          const formData = new FormData();
          formData.append('event', 'connected');
          formData.append('datetime', moment().toISOString());
          formData.append('clientId', SigmaChat.#clientId);
          const options = {
            method: 'POST',
            body: formData
          }
          fetch(process.env.MIX_PUSHER_HOST + '/chat/sigmaV2/callback/' + SigmaChat.#options.id, options);

          break;
        default:
          document.getElementById('sigma-connecting').style.display = 'none';
          document.getElementById('sigma-offline').style.display = 'flex';
          break;
      }
    }, 1000);
  }

  static connect() {
    SigmaChat.#connectSocket();
    document.getElementById('sigma-connecting').style.display = 'flex';
    document.getElementById('sigma-offline').style.display = 'none';
    this.#checkStatus();

  }

  static #terminate() {
    if(sessionStorage && SigmaChat.#options.keepHistory) {
      sessionStorage.removeItem('sigma-chat-history');
    }
    if(this.#clientId){
      const formData = new FormData();
      formData.append('message', '!sigma@@terminate');
      formData.append('clientId', this.#clientId);
      Object.entries(this.#user ).forEach((data) => {
        formData.append(`user[${data[0]}]`, data[1]);
      });
      const options = {
        method: 'POST',
        body: formData
      }
      fetch(process.env.MIX_PUSHER_HOST + '/chat/sigmaV2/callback/' + SigmaChat.#options.id, options);
    }
    if(SigmaChat.#atChat && SigmaChat.#options.hasSurvey && !SigmaChat.#atSurvey){
      SigmaChat.#atSurvey = true;
      let root = document.querySelector('.sigma-messages')
      root.replaceChildren();
      return;
    }
    if(SigmaChat.#fromForm){
      SigmaChat.#fromForm = false;
      SigmaChat.#atChat = false;
      SigmaChat.#atSurvey = false;
      SigmaChat.#user = null;
      SigmaChat.#clientId = null;
    }
    const chat = document.querySelector('.sigma-container')
    document.body.removeChild(chat);
    Echo.disconnect();
    if(SigmaChat.#healthCheck){
      clearInterval(SigmaChat.#healthCheck);
      SigmaChat.#healthCheck = null;
      SigmaChat.#state = 'disconected';
    }
    SigmaChat.#currentAudio = null;
    const event = new Event('SigmaChat-Close');
    document.dispatchEvent(event);
  }

  static close() {
    if(SigmaChat.#options.noConfirmTerminate){
      SigmaChat.#terminate();
    } else {
      document.getElementById('sigma-close-confirm').style.display = 'flex';
    }
  }

  static isOpen() {
    return (document.querySelector('.sigma-container') != null) || (document.querySelector('div[style*="display: flex"].sigma-minimized') != null);
  }

  static isMinimized() {
    return this.#minimized;
  }

  static getUnseenMessages() {
    return this.#unseenMessages;
  }

  static continueChat(){
    document.getElementById('sigma-close-confirm').style.display = 'none';
  }

  static closeChat(){
    var notification = document.getElementById('sigma-close-confirm');
    if(notification){
      notification.style.display = 'none';
    }
    SigmaChat.#terminate();
  }

  static closePopup() {
    const popup = document.querySelector('.sigma-popup');
    popup.remove();
  };

}

